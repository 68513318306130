<template>
  <training-detail :is-create-form="false" />
</template>

<script>
import TrainingDetail from './components/TrainingDetail.vue'

export default {
  name: 'EditNews',
  components: { TrainingDetail }
}
</script>

